<template>
    <div class="share_article">
        <Header style="padding: 0px 16px 0px;" @appOpen="followedBtn"></Header>
        <!-- <iframe src="" frameborder="0" id="ccIframe"></iframe> -->
        <div class="share_content">
            <div class="cover_bg" v-if="this.isFree == false" @click.stop="followedBtn">
                <!-- <img :src="sectionList.cover" alt=""> -->
            </div>
            <div class="share_video">
              <!-- 如果 sectionList.video 包含 "live.vhall.com"，则使用 iframe -->
              <iframe v-if="isFree && sectionList.video.includes('live.vhall.com')"
                      :src="sectionList.video" frameborder="0"
                      :style="videoHeight"
                      width="90%"
                      allow="camera *;microphone *; display-capture" allowfullscreen="true"
              ></iframe>

              <!-- 否则使用视频播放器 -->
              <vue-aliplayer-v2 v-else-if="isFree" :source="sectionList.video" ref="VueAliplayerV2" preload="false" :style="videoHeight" />
<!--                <vue-aliplayer-v2 v-if="this.isFree == true" :source="sectionList.video" ref="VueAliplayerV2"-->
<!--                    preload="false" :style="videoHeight" />-->
            </div>
            <div class="share_content_title">
                <div class="video_title">
                    {{ sectionList.title }}
                </div>
                <div class="view_counts is-flex">
                    <p style="padding-right: 24px">{{ sectionList.view_count }}次播放</p>
                    <p>{{ sectionList.created_at }}</p>
                </div>
            </div>
            <div style="width: 100%; height: 8px; background: #F8F8F8; padding: 0px"></div>
            <div class="lecturer_message">
                <p class="lecturer">讲师介绍</p>
                <div class="share_content_message is-flex">
                    <div class="share_message_left is-flex">
                        <img :src="userList.avatar" alt="" class="head_portrait">
                        <div class="name_time">
                            <p class="name">{{ userList.name }}</p>
                            <p class="time">{{ sectionList.created_at }}</p>
                        </div>
                    </div>
                    <div class="share_message_right is-flex">
                        <button class="followed_btn" @click="followedBtn"><img v-if="sectionList.isFollow === false"
                                src="../assets/share_followed.png" alt="">{{ sectionList.isFollow === false ? '关注' :
                                        '已关注'
                                }}</button>
                    </div>
                </div>
                <div class="introduce" v-html="userList.describe">
                    {{userList.describe}}
                </div>
            </div>
            <div style="width: 100%; height: 8px; background: #F8F8F8; padding: 0px"></div>
            <div class="comment">
                <p class="comment_title">评论</p>
                <div class="comment_area" v-for="(item, index) in CommentData" :key="index">
                    <div class="is-flex comment_nav">
                        <img :src="item.avatar" alt="" class="head_portrait">
                        <p class="user_name">{{ item.name }}</p>
                    </div>
                    <div class="comment_content">{{ item.body }}</div>
                    <template v-if="item.children">
                        <div style="margin-right: 16px; margin-left: 42px; padding: 10px; background: #F1F1F1"
                            v-for="(items, index) in item.children" :key="index">
                            <p>{{ item.author_user_id == items.user_id ? '作者' : items.name }} : {{ items.body }}</p>
                        </div>
                    </template>
                    <div class="comment_collect is-flex">
                        <p>{{ item.created_at }}</p>
                        <div class="is-flex collect_right" style="align-items: center;">
                            <img class="commnet_icon" src="../assets/icon_comment.png" alt="">
                            <img class="vote_icon" src="../assets/icon_like.png" alt="">
                            <p class="vote_count" v-if="item.vote_count !== 0">{{ item.vote_count }}</p>
                            <img class="more_icon" src="../assets/icon_more.png" alt="">
                        </div>
                    </div>
                    <div style="width: 100%; height: 1px; background: #ECECEC;"></div>
                </div>
            </div>
        </div>
        <Footer isShare="section" :detail="sectionList" @appOpen="followedBtn" />
    </div>
</template>
<script>
import {
    requestDataTGPMethod,
} from "../utils/appManager";
import VueAliplayerV2 from "vue-aliplayer-v2";
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default {
    components: {
        Header,
        Footer,
        VueAliplayerV2
    },
    data() {
        return {
            videoHeight: "height:200px;margin-left:5%;",
            sectionList: {},
            userList: {},
            useAlilayer: true,
            CommentData: [],
            classId: '',
            isFree: false,
        };
    },
    mounted() {
        this.requestLivingData();
        this.requestCommentData();
    },
    created() {
        window["getBannerData"] = (data) => {
            window.document.title = data.data.courses.title

            this.sectionList = data.data.section
            if (this.sectionList.price == 0 || this.sectionList.price == undefined) {
                console.log("免费");
                this.isFree = true;
            } else {
                console.log("付费");
                this.isFree = false;
                setTimeout(() => {
                    var playButton = document.getElementsByClassName("share_video")[0];
                    playButton.style.display = "none";
                }, 300);
            }
            this.userList = data.data.user
        };
        window["getCommentData"] = (data) => {
            this.CommentData = data.data
        };
    },
    methods: {
        requestLivingData() {
            const { id } = this.$route.params
            this.classId = id;
            requestDataTGPMethod(
                "requestData",
                "v3/courses/infoById",
                { id },
                "getBannerData",
                "0"
            );
        },
        requestCommentData() {
            requestDataTGPMethod(
                "requestData",
                "v4/comments/list",
                { commentable_type: 'sections', commentable_id: this.classId, page: 1, pageSize: 20 },
                "getCommentData",
                "0"
            );
        },
        followedBtn() {
            // 通用协议地址
            // iOS 下载地址
            var iOSDownloadUrl = "https://apps.apple.com/cn/app/%E5%90%AC%E8%82%A1%E7%A5%A8/id1453009085";
            // Android 下载地址
            var androidDownloadUrl = "https://a.app.qq.com/o/simple.jsp?pkgname=com.company.listenStock"

            var commSchemeUrl = 'https://api.tinggupiao.com.cn/';
            let u = navigator.userAgent
            var isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isIos) {
                commSchemeUrl = commSchemeUrl + encodeURIComponent('action:9999?url=127.0.0.1&&statusColor=0X000000&&fileName=WhiteClassDetail&&haveNav=0&&firstLoad={"id":' + this.classId + ',"haveBuy":"1"}')
                console.log(commSchemeUrl);
                if (u.toLocaleLowerCase().match("qq")) {
                    commSchemeUrl = "https://a.app.qq.com/o/simple.jsp?pkgname=com.company.listenStock&ios_schema=" + encodeURIComponent('scxListenStock://' +'action:9999?url=127.0.0.1&&statusColor=0X000000&&fileName=WhiteClassDetail&&haveNav=0&&firstLoad={"id":' + this.classId + ',"haveBuy":"1"}')
                }
                window.location.href = commSchemeUrl;
            } else {
                console.log(commSchemeUrl);
                commSchemeUrl = 'scxlistenstock://' + 'action:9999?url=127.0.0.1&&statusColor=0X000000&&fileName=WhiteClassDetail&&haveNav=0&&firstLoad={"id":' + this.classId + ',"haveBuy":"1"}'
                window.location.href = commSchemeUrl;
            }
            // 3秒后没打开，直接跳转下载页面
            var appDownload = setTimeout(function () {
                console.log("下载app");
                if (isIos) {
                    window.location.href = iOSDownloadUrl;
                } else {
                    window.location.href = androidDownloadUrl;
                }
            }, 3000);
            if (!isIos) {
                document.addEventListener('visibilitychange webkitvisibilitychange', function () {
                    // 页面隐藏，打开app，清除下载
                    if (document.hidden || document.webkitHidden) {
                        clearTimeout(appDownload)
                    }
                })
                window.addEventListener('pagehide', function () {
                    clearTimeout(appDownload)
                })
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.is-flex {
    display: flex;
}

.share_article {
    height: 100%;

    //   padding: 0px 16px 0px;
    .share_content {

        //   width: 100;
        //   padding-top: 24px;
        .cover_bg {
            width: 100%;
            height: 200px;
            z-index: 300;
            background-color: black;
        }

        .share_video {
            height: 200px;

        }

        .share_content_title {
            // height: 77px;
            // line-height: 77px;
            padding: 0px 16px 0px;

            .video_title {
                font-size: 20px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 500;
                color: #333333;
                padding-top: 24px;
            }

            .view_counts {
                padding-top: 12px;
                padding-bottom: 24px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
            }

        }

        .lecturer_message {
            padding: 0px 16px 0px;

            .lecturer {
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                padding: 24px 0px 8px 0px;
            }

            .share_content_message {
                padding-top: 12px;
                height: 74px;
                align-items: center;
                justify-content: space-between;

                .share_message_left {
                    .head_portrait {
                        width: 40px;
                        height: 40px;
                        border-radius: 20px;
                    }

                    .name_time {
                        padding-left: 8px;

                        .name {
                            font-size: 16px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                        }

                        .time {
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #999999;
                        }
                    }
                }

                .share_message_right {
                    .followed_btn {
                        width: 74px;
                        height: 30px;
                        background: #F8F8F8;
                        border-radius: 15px;
                        border: 0px;
                        font-size: 14px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #EF0923;
                    }
                }
            }

            .introduce {
                padding-bottom: 24px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                text-align: justify
            }
        }

        .comment {
            .comment_title {
                padding: 0px 16px 0px;
                padding-top: 12px;
                padding-top: 8px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
            }

            .comment_area {

                .comment_nav {
                    padding: 0px 16px 0px;
                    padding-top: 16px;

                    .head_portrait {
                        width: 34px;
                        height: 34px;
                        border: 1px solid #F8F8F8;
                        border-radius: 17px;
                    }

                    .user_name {
                        padding-left: 8px;
                        padding-top: 3px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                    }
                }

                .comment_content {
                    padding: 0px 16px 0px;
                    padding-left: 42px;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }

                .comment_collect {
                    padding: 0px 16px 0px;
                    justify-content: space-between;
                    align-items: center;
                    height: 54px;
                    padding-left: 42px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;

                    .collect_right {
                        .commnet_icon {
                            padding-right: 16px
                        }

                        .vote_icon {
                            padding-right: 4px
                        }

                        .vote_count {
                            padding-right: 8px;
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #999999;
                        }
                    }
                }
            }
        }
    }
}

/deep/ .prism-player {
    z-index: 1;

    .prism-big-play-btn {
        display: none;
        top: 50%;
        left: 50% !important;
        transform: translate(-50%, -50%);
    }

    height: none;

    .prism-controlbar {
        .prism-controlbar-bg {
            display: inline-block;
        }

        .prism-cc-btn {
            display: none;
        }

        .prism-live-display {
            display: none;
            visibility: hidden;
        }
    }
}
</style>
